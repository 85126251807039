<template>
  <div class="main">
    <img class="login-bg" src="@/assets/login-background.png" alt="">
    <div class="content">
      <div v-if="loginInfo.messageInfo" class="contract-box">
        <div class="title">{{ contractDetail.title }}</div>
        <div v-if="contractDetail.contractName" class="info">{{ contractDetail.contractName }}</div>
        <div v-if="contractDetail.userName" class="info">{{ contractDetail.userName }}</div>
      </div>
      <div class="apptype-box">
        <div class="apptype-item" @click="toMiniProgram">
          <img src="@/assets/wechat.png" alt="">
          <div class="text">在微信中打开</div>
        </div>
        <div v-if="!hideWebInit" class="apptype-item" @click="toBrowser">
          <img src="@/assets/browser.png" alt="">
          <div class="text">在浏览器中打开</div>
        </div>
      </div>
      <div class="assist">更多操作请点击在微信中打开</div>
    </div>
  </div>
</template>
<script>
import { getLoginInfoByLinkCode, getMiniProgramScheme } from '@/util/api'
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      contractDetail: {
        title: '',
        contractName: '',
        userName: ''
      },
      miniProgramUrl: '',
      loginInfo: {},
      hideWebInit: false
    }
  },
  computed: {
    ...mapState(['linkCode', 'linkType', 'loginName', 'tenantId'])
  },
  async created() {
    this.Analysis()
    this.$store.commit('setScrollTop', 0)
    this.$store.commit('setResultStatus', null)
    this.$store.commit('setSignPartyType', null)
    this.$store.commit('setResultInfo', null)
    console.log('route', this.$route.params)
    const { linkCode, linkType } = this.$route.params
    if (linkCode && linkType) {
      await this.getLoginInfo()
    }
    if(linkCode && (linkType == 2 || linkType == 4 || linkType == 6 || linkType == 61)) {
      this.hideWebInit = true
      this.toMiniProgram()
    }
  },
  methods: {
    Analysis() {
      let href = location.href.split('/#') ? location.href.split('/#')[0] : ''
      // 埋点（生产环境）
      // https://collect.data.jyq.pub
      // 埋点（测试环境）
      // var trackBaseUrl = "http://39.101.169.170:7778";
      console.log(location.href)
      var trackBaseUrl = href;
      // 游磊磊本地
      // var trackBaseUrl = 'http://172.17.241.218:7778'
      let params = {
        // 分配的应用id
        appId: "jyq",
        // 埋点上报域名
        url: trackBaseUrl,
        // 会话id（这个由前端自己生成吧）
        sessionId: "jdkslafjdklda442333jkldsfjß",
        // 是否显示调试日志
        showLog: true,
      };

      this.$Analysis.init(params);
      // this.onEvent()
    },
    ...mapMutations(['setScrollTop', 'setResultStatus', 'userInfo', 'setSignPartyType', 'setResultInfo']),
    async getLoginInfo() {
      const data = await getLoginInfoByLinkCode({
        linkCode: this.$route.params.linkCode,
        linkType: this.$route.params.linkType
      })
      this.loginInfo = data
      if(data.messageInfo) {
        if(data.messageInfo.indexOf('请您尽快处理') != -1) {
        this.contractDetail.title = data.messageInfo
        } else {
          this.contractDetail.title = data.messageInfo.split(/，/)[0]
          this.contractDetail.contractName = data.messageInfo.split(/，/)[1].split(/,/)[0]
          this.contractDetail.userName = data.messageInfo.split(/，/)[1].split(/,/)[1]
        }
      }
      this.$store.commit('setTenantId', data.tenantId)
      this.$store.commit('setLoginName', data.loginName)
    },
    async getScheme() {
      this.miniProgramUrl = await getMiniProgramScheme({
        appId: 'wx9881c2ba05aeb060',
        // envVersion: 'release',
        envVersion: 'develop',
        path: '/pages/login/index',
        query: 'loginInfo=' + encodeURIComponent(JSON.stringify(this.loginInfo))
      })
    },
    toBrowser() {
      this.$store.commit('setAppType', 'browser')
      const { linkCode, linkType } = this.$route.params
      if (linkCode && linkType) {
        this.$router.push({
          path: '/login', query: {
            linkCode,
            linkType,
            ...this.loginInfo
          }
        })
      } else {
        this.$router.push({
          path: '/login', query: {
            linkCode: this.linkCode,
            linkType: this.linkType,
            loginName: this.loginName,
            tenantId: this.tenantId
          }
        })
      }
      const paramsW = {
        // 必填
        eventId: 'hcq_browser_open',
        // 必填
        eventType: 'click',
        pageId: 'hcq.open',
        userId: this.userInfo.id || ''
      }
      this.$Analysis.onEvent(paramsW)
    },
    async toMiniProgram() {
      await this.getScheme()
      this.$store.commit('setAppType', 'miniProgram')
      const paramsW = {
        // 必填
        eventId: 'hcq_mini_open',
        // 必填
        eventType: 'click',
        pageId: 'hcq.open',
        userId: this.userInfo.id || ''
      }
      this.$Analysis.onEvent(paramsW)
      window.open(this.miniProgramUrl, '_self')
    }
  }
}
</script>

<style lang="less" scoped>
.login-bg {
  width: 100%;
  height: 250px;
  z-index: 1;
}

.content {
  transform: translateY(-50px);
  height: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #FFF;
  padding: 28px 23px;
  z-index: 2;
}

.contract-box {
  border-radius: 4px;
  box-shadow: 0rem 4px 10px .5px rgba(130, 130, 130, 0.10);
  padding: 24px 16px;
  margin-bottom: 24px;

  .title {
    color: #30586C;
    font-size: 17px;
    margin: 0 auto 26px;
    font-weight: bold;
  }

  .info {
    color: #8B96A6;
    font-size: 14px;
    margin-bottom: 18px;
  }

  .info:nth-last-child(1) {
    margin-bottom: 0px;
  }
}

.apptype-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .apptype-item {
    flex: 1;
    margin-right: 19px;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #F8F8F8;
    border-radius: 6px;

    img {
      margin-bottom: 12px;
      width: 40px;
      height: 40px;
    }

    .text {
      font-size: 14px;
      color: #464646;
    }
  }

  .apptype-item:nth-last-child(1) {
    margin-right: 0px;
  }
}

.assist {
  color: #8B96A6;
  font-size: 12px;
}
</style>